/*###########################################################################
// Modals
//#########################################################################*/

const modals = {
    initialisedModals: false,

    elements: {},

    init() {
        const self = this

        self.elements.modals = document.querySelectorAll('.modal')
        self.elements.modalTriggers = document.querySelectorAll('.modal__trigger')

        if (self.elements.modals !== null) {
            for (let i = 0; i < self.elements.modals.length; i++) {
                const modal = self.elements.modals[i]

                // Handle each modal
                //
                self.handleModal(modal)
            }
        }

        if (self.elements.modalTriggers !== null) {
            for (let i = 0; i < self.elements.modalTriggers.length; i++) {
                const modalTrigger = self.elements.modalTriggers[i]
                const targetModal = document.querySelector(modalTrigger.getAttribute('data-modal'))

                if (targetModal !== null) {
                    modalTrigger.addEventListener('click', function (e) {
                        e.preventDefault()

                        self.handleOpen(targetModal, modalTrigger)
                    })
                } else {
                    console.error('Unable to find target modal for modal trigger.')
                }
            }

            self.initialisedModals = true
        }

        if (typeof htmx !== 'undefined') {
            // After swap re init
            //
            htmx.on('htmx:afterSwap', function (event) {
                self.elements.modalTriggers = document.querySelectorAll('.modal__trigger')

                setTimeout(function () {
                    if (self.elements.modalTriggers !== null) {
                        for (let i = 0; i < self.elements.modalTriggers.length; i++) {
                            const modalTrigger = self.elements.modalTriggers[i]
                            const targetModal = document.querySelector(modalTrigger.getAttribute('data-modal'))

                            if (targetModal !== null) {
                                modalTrigger.addEventListener('click', function (e) {
                                    e.preventDefault()

                                    self.handleOpen(targetModal, modalTrigger)
                                })
                            } else {
                                console.error('Unable to find target modal for modal trigger.')
                            }
                        }
                    }
                }, 100)
            })
        }
    },

    handleModal(modal) {
        const self = this

        // Handle close
        //
        self.handleClose(modal)
    },

    handleOpen(modal, modalTrigger) {
        const self = this

        // Open modal
        //
        modal.classList.add('open')

        // Lock body scroll
        //
        document.documentElement.style.overflow = 'hidden'

        // Handle dynamic modal
        //
        if (modal.classList.contains('modal--dynamic') && modalTrigger.getAttribute('data-modal-content') !== '') {
            self.handleDynamicModalOpen(modal, modalTrigger.getAttribute('data-modal-content'))
        }

        setTimeout(function () {
            // Calculate and set overlay height
            //
            self.updateOverlayHeight(modal, true)

            // Handle any proxy modal triggers
            //
            const modalClose = modal.querySelector('.modal__close-btn')
            const modalProxyTrigger = modal.querySelector('.modal__proxy-trigger')
            if (modalProxyTrigger !== null) {
                self.handleModalProxyTrigger(modalProxyTrigger, modalClose)
            }
        }, 250)

        // Dispatch event to be listened for elsewhere
        //
        window.dispatchEvent(new Event("modalOpen"));
    },

    handleClose(modal) {
        const self = this

        const modalClose = modal.querySelector('.modal__close-btn')
        modalClose.addEventListener('click', function (e) {
            self.closeModal(modal)

            // Prevent immediate trigger of nav dropdown hover
            //
            document.querySelector('.header').style.pointerEvents = 'none'
            setTimeout(function () {
                document.querySelector('.header').style.pointerEvents = ''
            }, 1000)
        })

        const modalOverlay = modal.querySelector('.modal__overlay')
        modalOverlay.addEventListener('click', function (e) {
            self.closeModal(modal)
        })
    },

    closeModal(modal) {
        const self = this

        // Close modal
        //
        modal.classList.remove('open')

        // Unlock scroll
        //
        document.documentElement.style.overflow = ''

        setTimeout(function () {
            // Clear modal scroll
            //
            modal.scrollTop = 0

            // Reset overlay height
            //
            self.resetOverlayHeight(modal)
        }, 300)
    },

    updateOverlayHeight(modal, watchImages) {
        const self = this

        setTimeout(function () {
            const modalScrollHeight = (window.innerHeight > modal.scrollHeight ? window.innerHeight : modal.scrollHeight) + 'px';
            document.documentElement.style.setProperty("--modalOverlayHeight", modalScrollHeight);
        }, 250)

        if (watchImages === true) {
            const modalImages = modal.querySelectorAll('img')
            for (let i = 0; i < modalImages.length; i++) {
                const modalImage = modalImages[i]

                modalImage.addEventListener('load', function () {
                    setTimeout(function () {
                        const modalScrollHeight = (window.innerHeight > modal.scrollHeight ? window.innerHeight : modal.scrollHeight) + 'px';
                        document.documentElement.style.setProperty("--modalOverlayHeight", modalScrollHeight);
                    }, 250)
                })
            }
        }

        window.addEventListener('ticketTableBlockChange', function () {
            setTimeout(function () {
                const modalScrollHeight = (window.innerHeight > modal.scrollHeight ? window.innerHeight : modal.scrollHeight) + 'px';
                document.documentElement.style.setProperty("--modalOverlayHeight", modalScrollHeight);
            }, 100)
        })
    },

    resetOverlayHeight() {
        const self = this

        document.documentElement.style.setProperty("--modalOverlayHeight", "100%");
    },

    handleDynamicModalOpen(modal, modalContent) {
        const self = this

        modal.querySelector('.modal__content__inner').innerHTML = JSON.parse(modalContent)
    },

    handleModalProxyTrigger(modalProxyTrigger, modalClose) {
        const self = this

        const targetBtn = document.querySelector(modalProxyTrigger.getAttribute('data-modal-proxy-trigger'))

        modalProxyTrigger.addEventListener('click', function () {
            modalClose.click()

            setTimeout(function () {
                targetBtn.click()
            }, 500)
        })
    }
}

if (document.readyState === "complete") {
    modals.init()
} else {
    window.addEventListener('load', function () {
        modals.init()
    })
}
